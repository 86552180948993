import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CompanyPayment } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getPagination = (state: State) => state.pagination;

export const selectState = createFeatureSelector<State>('companyPayments');

export const selectAllItems = featureAdapter.getSelectors(selectState)
  .selectAll;

export const selectById = (id: string) =>
  createSelector(
    selectAllItems,
    (companyPayments: CompanyPayment[]) =>
      companyPayments && companyPayments.find((p) => p.id === +id),
  );

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectPagination = createSelector(selectState, getPagination);
