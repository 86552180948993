import { createAction, props } from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { CompanyPayment } from '../../models';

export const loadRequest = createAction(
  '[Company Payments] Load Request',
  props<{ companyId: number; properties: number[]; page: number }>(),
);

export const loadSuccess = createAction(
  '[Company Payments] Load Success',
  props<{ companyPayments: CompanyPayment[]; pagination: IPagination }>(),
);

export const loadFailure = createAction(
  '[Company Payments] Load Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Company Payments] Delete Request',
  props<{ reservationId: number; paymentId: number }>(),
);

export const deleteSuccess = createAction(
  '[Company Payments] Delete Success',
  props<{ paymentId: number }>(),
);

export const deleteFailure = createAction(
  '[Company Payments] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Company Payments] Reset State');
